import { Box, Typography, Paper } from '@mui/material'

export const AppplicationErrorScreen = ({
  errorMessage = 'An unexpected error occurred.',
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional background color
        zIndex: 1300, // Ensure it's above other elements
      }}
    >
      <Paper
        elevation={3}
        sx={{ padding: '20px', maxWidth: '400px', textAlign: 'center' }}
      >
        <Typography variant="h5" color="error" gutterBottom>
          Error
        </Typography>
        <Typography variant="body1">{errorMessage}</Typography>
      </Paper>
    </Box>
  )
}
