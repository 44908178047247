import { FC } from 'react'
import { ApplicationFrame } from '../../components/ApplicationFrame'
import nashLogo from '../../assets/nash-delivery/logo-lime.png'
import { Typography } from '@mui/material'

/**
 * Uber Eats Application
 */
export const NashDelivery: FC = () => {
  //   const [params] = useSearchParams()
  //   const session = params.get('session')!

  /**
   *
   */
  return (
    <ApplicationFrame heroImage={nashLogo} backgroundColor="#d4fe00">
      {/* Centralised Title with padding */}
      <Typography variant="h4" align="center" sx={{ p: 2 }}>
        Nash Delivery
      </Typography>
    </ApplicationFrame>
  )
}
