import { createBrowserRouter } from 'react-router-dom'
import { UberEats } from './uber-eats'
import { SumniCloudPrinter } from './sumni-cloud-printer'
import { FaxPlus } from './fax-plus'
import { EPOSNow } from './epos-now'
import { StuartDelivery } from './stuart-delivery'
import { UberDirectDelivery } from './uber-direct-delivery'
import { NashDelivery } from './nash-delivery'

/**
 * Router for the top level of the application
 */
export const router = createBrowserRouter([
  { path: '/', element: <>Hello World 1</> },
  { path: '/uber-eats', index: true, element: <UberEats /> },
  { path: 'sumni-cloud-printer', index: true, element: <SumniCloudPrinter /> },
  { path: 'fax-plus', index: true, element: <FaxPlus /> },
  { path: 'epos-now', index: true, element: <EPOSNow /> },
  { path: 'stuart-delivery', index: true, element: <StuartDelivery /> },
  {
    path: 'uber-direct-delivery',
    index: true,
    element: <UberDirectDelivery />,
  },
  { path: 'nash-delivery', index: true, element: <NashDelivery /> },
])
