import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { FC, useState } from 'react'
import { useClientApiMutation } from '../../hooks/useClientApiCall'
import { useSearchParams } from 'react-router-dom'
import { UseQueryResult } from 'react-query'
import phone from 'phone'

/**
 * Dialog component that aloow hte user to add a pirnter via name, serial number
 * and some other options
 */
export interface AddFaxDialogProps extends DialogProps {
  connection: UseQueryResult<any, unknown>
}

/**
 * Add Printer Dialog
 */
export const AddFaxDialog: FC<AddFaxDialogProps> = (props) => {
  const [name, setName] = useState('Defauly Fax')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [status, setStatus] = useState('PLACED')
  const [params] = useSearchParams()
  const session = params.get('session')!
  const addFax = useClientApiMutation(session, 'add-fax')

  /**
   * Derive a small check to see if the form is valid
   */
  const isFormValid =
    name.length > 0 && phoneNumber.length > 0 && status.length > 0

  const handleAddFax = () => {
    addFax.mutate(
      { name, phoneNumber, status },
      {
        onSuccess: () => {
          void props.connection.refetch()
          props.onClose?.({}, 'escapeKeyDown')
        },
      }
    )
  }

  return (
    <Dialog {...props} disableEscapeKeyDown={addFax.isLoading}>
      <DialogTitle>Add Fax</DialogTitle>
      <DialogContent>
        {addFax.isError && addFax.error && (
          <FormHelperText error>{addFax.error as any}</FormHelperText>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <TextField
              placeholder="e.g: +1234567890"
              label="Phone Number"
              fullWidth
              size="small"
              value={phoneNumber}
              autoFocus
              required
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <FormHelperText>
              The phone number of the fax, e.g: +1234567890
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="e.g: Kitchen Fax"
              label="Fax Name"
              variant="outlined"
              fullWidth
              size="small"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
            <FormHelperText>
              A Frendly name for the fax, e.g: Kitchen Fax
            </FormHelperText>
          </Grid>
          <Grid item xs={12}>
            {/* Dropdown choosing when to print the order */}
            <Select
              fullWidth
              size="small"
              defaultValue={'PLACED'}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="PLACED">PLACED</MenuItem>
              <MenuItem value="ACCEPTED">ACCEPTED</MenuItem>
              <MenuItem value="READY">READY</MenuItem>
              <MenuItem value="REJECTED">REJECTED</MenuItem>
              <MenuItem value="CANCELLED">CANCELLED</MenuItem>
              <MenuItem value="COMPLETED">COMPLETED</MenuItem>
            </Select>
            <FormHelperText>
              The order will only be sent to the fax when it reaches this status
            </FormHelperText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAddFax}
          disabled={isFormValid === false || addFax.isLoading}
        >
          Add Fax
        </Button>
      </DialogActions>
    </Dialog>
  )
}
