import { FC, useState } from 'react'
import {
  useClientApiCall,
  useClientApiMutation,
} from '../../hooks/useClientApiCall'
import { useSearchParams } from 'react-router-dom'
import { ApplicationLoadingScreen } from '../../components/ApplicationLoadingScreen'
import { AppplicationErrorScreen } from '../../components/ApplicationErrorScreen'
import { Button, Container, MenuItem, Select } from '@mui/material'
import { UseQueryResult } from 'react-query'

/**
 * Uber Eats Application
 */
export const UberEats: FC = () => {
  const [params] = useSearchParams()
  const status = useClientApiCall<any>(params.get('session')!, 'status')

  // Shortcuts for loading and error states
  if (status.isLoading) return <ApplicationLoadingScreen />
  if (status.isError) return <AppplicationErrorScreen />

  // This will be true if we are already connected to the store
  const isAlreadyConnected = status.data?.connection.externalLocationId ?? false

  if (!isAlreadyConnected) {
    return <DisconnectedView status={status} />
  }

  return <ConnectedView status={status} />
}

export const DisconnectedView: FC<{ status: UseQueryResult<any> }> = (
  props
) => {
  const [params] = useSearchParams()
  const connectMutation = useClientApiMutation<any>(
    params.get('session')!,
    'connect'
  )
  const [selectedStoreId, setSelectedStoreId] = useState<string | undefined>()

  /**
   *
   */
  const doConnect = () => {
    connectMutation.mutate({ storeId: selectedStoreId })
  }

  return (
    <Container>
      <Select<string>
        onChange={(event) => setSelectedStoreId(event.target.value)}
      >
        {props.status.data?.stores?.map((store: any) => (
          <MenuItem
            value={store.store_id}
            selected={selectedStoreId === store.store_id}
          >
            {store.name}
          </MenuItem>
        ))}
      </Select>
      {selectedStoreId && <Button onClick={doConnect}>Connect</Button>}
    </Container>
  )
}

export const ConnectedView: FC<{ status: UseQueryResult<any> }> = ({
  status,
}) => {
  const [params] = useSearchParams()
  const disconnect = useClientApiMutation<any>(
    params.get('session')!,
    'connect',
    'DELETE'
  )

  /**
   *
   */
  const doDisconnect = () => {
    status.data?.connection.externalLocationId &&
      disconnect.mutate({ storeId: status.data?.connection.externalLocationId })
  }

  return (
    <Container>
      <Button onClick={doDisconnect}>Disconnect</Button>
    </Container>
  )
}
