import { FC, useState } from 'react'
import { ApplicationFrame } from '../../components/ApplicationFrame'
import blackPrinterImage from '../../assets/sumni-cloud-printer/printers-black.jpg'
import {
  useClientApiCall,
  useClientApiMutation,
} from '../../hooks/useClientApiCall'
import { useSearchParams } from 'react-router-dom'
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material'
import { ApplicationLoadingScreen } from '../../components/ApplicationLoadingScreen'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AddFaxDialog } from './AddFaxDialog'

/**
 * Uber Eats Application
 */
export const FaxPlus: FC = () => {
  const [params] = useSearchParams()
  const [addFaxDialogOpen, setAddFaxDialogOpen] = useState(false)
  const session = params.get('session')!
  const connection = useClientApiCall<any>(session, 'connection')

  /**
   * While we are loading the connection, we will show a loading spinner
   */
  if (connection.isLoading) {
    return <ApplicationLoadingScreen />
  }

  /**
   *
   */
  return (
    <ApplicationFrame heroImage={blackPrinterImage}>
      {addFaxDialogOpen && (
        <AddFaxDialog
          connection={connection}
          open={addFaxDialogOpen}
          onClose={() => setAddFaxDialogOpen(false)}
        />
      )}
      {/* Centralised Title with padding */}
      <Typography variant="h4" align="center" sx={{ p: 2 }}>
        Fax
      </Typography>
      {/* Button on the right to add printer*/}
      <Box pb={2}>
        <Button onClick={() => setAddFaxDialogOpen(true)}>Add Fax</Button>
      </Box>

      <ConnectedFaxes />
    </ApplicationFrame>
  )
}

export const ConnectedFaxes: FC = () => {
  const [params] = useSearchParams()
  const session = params.get('session')!
  const connection = useClientApiCall<any>(session, 'connection')
  const testPrinter = useClientApiMutation(session, 'test-fax')
  const removeFax = useClientApiMutation(session, 'remove-fax')

  /**
   * Remove Fax Handler
   */
  const removeFaxHandler = (options: { phoneNumber: string }) => {
    removeFax.mutate(options, {
      onSettled: () => connection.refetch(),
    })
  }

  return (
    <Box>
      <Typography variant="h6">Connected Faxes</Typography>
      {connection.data?.faxes?.map(
        (printer: { name: string; phoneNumber: string; status: string }) => (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {/* Small green / red dot  */}
              <Typography variant="body1">{printer.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Phone Number */}
              <Typography variant="body2">
                Phone Number: {printer.phoneNumber}
              </Typography>
              {/* Status */}
              <Typography variant="body2">Status: {printer.status}</Typography>
            </AccordionDetails>
            <AccordionActions>
              <Button
                onClick={() =>
                  removeFaxHandler({ phoneNumber: printer.phoneNumber })
                }
                disabled={removeFax.isLoading}
              >
                Delete
              </Button>
              <Button
                onClick={() =>
                  testPrinter.mutate({ phoneNumber: printer.phoneNumber })
                }
                disabled={testPrinter.isLoading}
              >
                Test Print
              </Button>
            </AccordionActions>
          </Accordion>
        )
      )}
    </Box>
  )
}
