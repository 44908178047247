import 'react-toastify/dist/ReactToastify.css'
import CssBaseline from '@mui/material/CssBaseline'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { DateFnsProvider } from 'react-hook-form-mui/dist/date-fns'
import { ToastContainer } from 'react-toastify'
import { FC, StrictMode } from 'react'
import { RouterProvider } from 'react-router-dom'
import { router } from './routes'

const queryClient = new QueryClient()

const theme = createTheme()

/**
 *
 */
export interface AppProps {}

/**
 * Application
 */
export const App: FC<AppProps> = () => {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <ToastContainer />
        <DateFnsProvider>
          <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
          </ThemeProvider>
        </DateFnsProvider>
      </QueryClientProvider>
    </StrictMode>
  )
}
