import { FC } from 'react'
import { ApplicationFrame } from '../../components/ApplicationFrame'
import uberLogo from '../../assets/uber-direct-delivery/logo.png'
import { Typography } from '@mui/material'

/**
 * Uber Eats Application
 */
export const UberDirectDelivery: FC = () => {
  //   const [params] = useSearchParams()
  //   const session = params.get('session')!

  /**
   *
   */
  return (
    <ApplicationFrame heroImage={uberLogo} backgroundColor="#0092ff">
      {/* Centralised Title with padding */}
      <Typography variant="h4" align="center" sx={{ p: 2 }}>
        Uber Direct
      </Typography>
    </ApplicationFrame>
  )
}
