import { useMutation, useQuery } from 'react-query'

/**
 * Splits and parses the JWT session token and returns the contents
 */
export const useSessionDetails = (sessionToken: string) => {
  if (!sessionToken) {
    return {}
  }
  const [, payload] = sessionToken.split('.')
  const decodedPayload = JSON.parse(atob(payload))
  return decodedPayload
}

/**
 * Calls the API endpoint for a specific url
 */
export const useApiCall = <T>(sessionToken: string, url: string) => {
  return useQuery<T>([url], {
    enabled: !!sessionToken,
    queryFn: async () => {
      const response = await fetch(url, {
        headers: { 'X-Session-Token': sessionToken },
      })
      return response.json() as T
    },
  })
}

/**
 * Calls the /api/ endpoint for a specicif url
 */
export const useClientApiCall = <T>(sessionToken: string, path: string) => {
  const { clientId } = useSessionDetails(sessionToken)
  path = path.startsWith('/') ? path.replace('/', '') : path
  const url = `${APPS_API_DOMAIN_NAME}/clients/${clientId}/api/${path}`

  return useApiCall<T>(sessionToken, url)
}

export const useClientApiMutation = <T>(
  sessionToken: string,
  path: string,
  method: string = 'POST'
) => {
  const { clientId } = useSessionDetails(sessionToken)
  path = path.startsWith('/') ? path.replace('/', '') : path
  const url = `${APPS_API_DOMAIN_NAME}/clients/${clientId}/api/${path}`

  return useMutation<T, unknown, T>(async (data) => {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-Session-Token': sessionToken,
      },
      body: JSON.stringify(data),
    })

    return response.json()
  })
}
