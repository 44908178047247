import { Box, Grid } from '@mui/material'
import { FC } from 'react'

export interface ApplicationFrameProps {
  heroImage: string
  children: React.ReactNode
  backgroundColor?: string
}

/**
 * This component provides a frame for the application, the frame will be similar to a login page where the
 * left side will have a large image and the right side will have the application content, vertically along the right side
 */
export const ApplicationFrame: FC<ApplicationFrameProps> = ({
  children,
  heroImage,
  backgroundColor,
}) => {
  return (
    <Grid
      container
      component="main"
      spacing={2}
      sx={{ height: '100vh', overflowY: 'auto', overflowX: 'hidden' }}
    >
      <Grid
        item
        xs={false}
        sm={5}
        md={8}
        sx={{
          backgroundColor: backgroundColor
            ? backgroundColor
            : (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[50]
                  : theme.palette.grey[900],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '50%',
            height: '50%',
            backgroundImage: `url(${heroImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}
        ></Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={7}
        md={4}
        sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      >
        {children}
      </Grid>
    </Grid>
  )
}
